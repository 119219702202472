<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 sm:m-0 m-4">
        <div slot="no-body">

          <div class="vx-row no-gutter ">


              <div class="vx-col sm:w-full md:w-full lg:w-full">
                <div class="px-8 pt-8">

                  <div class="mb-4">
                    <h1 class="mb-4 text-font">เข้าสู่ระบบ Prapa On Mobile</h1>
                    <h3 class="text-font">ยินดีต้อนรับสู่ระบบบริหารประปา</h3>
                  </div>

                  <!--                  <vs-tab label="Firebase">-->
                  <!--                    <login-firebase></login-firebase>-->
                  <!--                  </vs-tab>-->

                  <!--                  <vs-tab label="Auth0">-->
                  <!--                    <login-auth0></login-auth0>-->
                  <!--                  </vs-tab>-->
                  <!--                </vs-tabs>-->

                </div>
              </div>
          </div>
        </div>
    </div>
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 sm:m-0 m-4 ">
      <vx-card>
        <div slot="no-body">

          <div class="vx-row no-gutter justify-center items-center">

            <vx-card card-border>

              <div class="vx-col sm:w-full md:w-full lg:w-full d-theme-dark-bg">
                <div class="px-8 pt-8 login-tabs-container">

                  <div class="mb-4">
                    <h1 class="mb-4 text-font">เข้าสู่ระบบ Prapa On Mobile</h1>
                    <h3 class="text-font">ยินดีต้อนรับสู่ระบบบริหารประปา</h3>
                  </div>

                  <login-jwt></login-jwt>

                  <!--                  <vs-tab label="Firebase">-->
                  <!--                    <login-firebase></login-firebase>-->
                  <!--                  </vs-tab>-->

                  <!--                  <vs-tab label="Auth0">-->
                  <!--                    <login-auth0></login-auth0>-->
                  <!--                  </vs-tab>-->
                  <!--                </vs-tabs>-->

                </div>
              </div>
            </vx-card>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>


<script>
import LoginJwt from "./LoginJWT.vue"

export default {
  components: {
    LoginJwt
  }
}
</script>

<style lang="scss">
.login-tabs-container {
  min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
.text-font{
  font-family: "FC Iconic";
}
.welcome {
  background-image: url("../../../assets/images/pages/background.png");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>

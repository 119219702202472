<template>
  <div class="mt-12">

    <div class="mb-4">
      <span class="text-font">รหัสองค์กร</span>
      <vs-input
        v-validate="'required'"
        data-vv-validate-on="blur"
        name="orgId"
        icon-no-border
        icon="icon icon-globe"
        icon-pack="feather"
        v-model="org_id"
        class="w-full mt-2 text-font"
        @keydown="onKeydown($event)"/>
      <span class="text-danger text-sm text-font" v-show="errors.has('orgId')">กรุณากรอกรหัสองค์กร</span>
    </div>

    <div class="mb-4">
      <span class="text-font">ชื่อผู้ใช้งาน</span>
      <vs-input
        v-validate="'required'"
        data-vv-validate-on="blur"
        name="username"
        icon-no-border
        icon="icon icon-user"
        icon-pack="feather"
        v-model="username"
        class="w-full mt-2 text-font"
        @keydown="onKeydown($event)"/>
      <span class="text-danger text-sm text-font" v-show="errors.has('username')">กรุณากรอกชื่อผู้ใช้งาน</span>
    </div>

    <div class="mb-8">
      <span class="text-font">รหัสผ่าน</span>
      <vs-input
        data-vv-validate-on="blur"
        v-validate="'required'"
        type="password"
        name="password"
        icon-no-border
        icon="icon icon-lock"
        icon-pack="feather"
        v-model="password"
        class="w-full mt-2 text-font"
        @keydown="onKeydown($event)"/>
      <span class="text-danger text-sm text-font" v-show="errors.has('password')">กรุณากรอกรหัสผ่าน</span>
    </div>

<!--    <div class="flex flex-wrap justify-between my-5">-->
<!--        <vs-checkbox v-model="checkbox_remember_me" class="mb-3">จดจำฉัน</vs-checkbox>-->
<!--        <router-link to="/pages/forgot-password">ลืมรหัสผ่าน?</router-link>-->
<!--    </div>-->
    <div class="mb-3">
      <vs-button class="text-font w-full" :disabled="!validateForm" @click="loginJWT" >เข้าสู่ระบบ</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      org_id: '',
      checkbox_remember_me: false
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.username != '' && this.password != '' && this.org_id != '';
    },
  },
  methods: {
    onKeydown(e){
      if(e.keyCode == 13 || e.which == 13) { //Enter keycode
        this.loginJWT()
      }
    },
    loginJWT() {
      this.$vs.loading()
      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        user: {
          username: this.username,
          password: this.password,
          org_id: this.org_id
        }
      }
      this.$store.dispatch('loginSession', payload).then(() => {
        this.$vs.loading.close()
      }).catch(error => {
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'ผิดพลาด',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      });
    }
  }
}
</script>
<style>
  .text-font{
    font-family: "FC Iconic";
  }
</style>

